
import {defineComponent, PropType} from 'vue';
import { PartnerReviewRequestResource} from '@/api/partnerReviewApi';
import CalendarSvg from '@/assets/img/calendarSvg.vue';
import { formatInTimeZone } from 'date-fns-tz';
import {useRouter} from 'vue-router';
import {reviewSingleQuiz} from '@/core/constants';

export default defineComponent({
  name: 'PartnerRequestListItem',
  components: {  CalendarSvg },
  props: {
    data: {
      type: Object as PropType<PartnerReviewRequestResource>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    }
  },
  setup(props) {
    const router = useRouter();

    const goToReviewSentRequest = () => {
      if(props.data.state === 'UPDATED'){
        router.push({
          name: reviewSingleQuiz ,
          params: {
            id: props.data.partner_review_quiz_id,
          },
        });
      }
    };

    const formatDate = (date: string) => {
      // eslint-disable-next-line max-len
      return formatInTimeZone(new Date(date), Intl.DateTimeFormat().resolvedOptions().timeZone, 'MMM d, yyyy');
    };


    return {
      formatDate,
      goToReviewSentRequest
    }
  },

});
