

import {defineComponent, onMounted, ref} from "vue";
import RequestPartnerNav from '@/components/RequestPartnerNav.vue';
import WhiteBoxMain from '@/components/WhiteBoxMain.vue';
import PartnerRequestListItem from '@/components/PartnerRequestListItem.vue';
import {reviewRequest} from '@/core/constants';
import {
  getApprovedRequests,
  PartnerReviewRequestResource
} from '@/api/partnerReviewApi';

export default defineComponent( {
  name: 'RequestApprovedPage',
methods: { reviewRequest() {
return reviewRequest} } ,
  components: {PartnerRequestListItem, WhiteBoxMain, RequestPartnerNav},
  setup() {
    const approvedRequest = ref<PartnerReviewRequestResource[]>([])


    onMounted(() => {
      getApprovedRequests().then(res => {
        approvedRequest.value = res;
      })
    })

    return {
      approvedRequest
    }
  }

});
