import req from '@/api/utilities/axios';
import {id} from 'date-fns/locale';

interface SendRequestToPartnersRequest {
  message: string;
  expire_in: number;
  quiz_ids: number[]
}
export const sendRequestToPartners = (payload: SendRequestToPartnersRequest): Promise<any> => {
  return req.post(`partner-review`, payload).then(data => data.data.data);
};


export const getSentRequest = (): Promise<PartnerReviewRequestResource[]> => {
  return req.get(`partner-review`).then(data => data.data.data);
};

export const getApprovedRequests = (): Promise<PartnerReviewRequestResource[]> => {
  return req.get(`partner-review/approved`).then(data => data.data.data);
};

export const getReviewRequests = (): Promise<PartnerReviewRequestResource[]> => {
  return req.get(`partner-review/review`).then(data => data.data.data);
};

export type PartnerReviewRequest = {
  id: number;
  quiz_id: number;
  partner_review_request_id: number;
  name: string;
  color: number;
  partner_review_questions: PartnerReviewQuestionResource[];
  original_answer: PartnerReviewAnswerResource;
  partner_answer: PartnerReviewAnswerResource;
  answer_history: PartnerReviewAnswerResource[];
  created_at: object | null;
  updated_at: object | null;
};
export const getReviewQuiz = (id: string): Promise<PartnerReviewQuizResource> => {
  return req.get(`partner-review/${id}`).then(data => data.data.data);
};

export const getClientPartnerReviewQuiz = (uuid: string, password: string): Promise<PartnerReviewQuizResource> => {
  return req.get(`partner-review/client/${uuid}`, {
    headers: {
      'Authorization': `Basic ${btoa(`${uuid}:${password}`)}`,
    }
  }).then(data => data.data.data);
};

export type PartnerReviewStoreAnswerRequest = {
  request_uuid: string;
  partner_review_quiz_id: number;
  answer: Record<string | number, string | string[]>
  password: string
}
export const partnerReviewStoreAnswerApi = (data:PartnerReviewStoreAnswerRequest): Promise<PartnerReviewQuizResource> => {
  return req.put(`partner-review/client/${data.request_uuid}`, data, {headers: {
      'Authorization': `Basic ${btoa(`${data.request_uuid}:${data.password}`)}`,
    }})

}

export interface PartnerReviewCompleteRequest {
  request_uuid: string;
  answer: Array<{id: number, value: string | string[]}>
}
export const getPartnerReviewCompleteRequest = (data: PartnerReviewCompleteRequest): Promise<PartnerReviewQuizResource> => {
  return req.post(`partner-review/${data.request_uuid}`, data).then(data => data.data.data);
}

export const partnerReviewGetReviewCount = (): Promise<{ count: number}> => req.get(`partner-review/review-count`).then(data => data.data.data);



export type PartnerReviewQuestionResource = {
  id: number;
  partner_review_quiz_id: number | null;
  question_id: number | null;
  category_id: number | null;
  text: string;
  position: number;
  is_required: boolean;
  type: string;
  answer_values: string[] | null;
  original_answer: PartnerReviewAnswerResource;
  partner_answer: PartnerReviewAnswerResource;
  answer_history: PartnerReviewAnswerResource[];
  created_at: string | null;
  updated_at: string | null;
};

type PartnerReviewAnswerResource = {
  id: number;
  answer_value: string | null;
  created_at: string | null;
  updated_at: string | null;
};

export type PartnerReviewQuizResource = {
  id: number;
  quiz_id: number;
  partner_review_request_id: number;
  partner_review_request: {
    id: number;
    uuid: string;
  }
  name: string;
  color: number;
  partner_review_questions: PartnerReviewQuestionResource[]
  created_at: string | null;
  updated_at: string | null;
}

export type PartnerReviewRequestResource = {
  id: number;
  quiz_id: number;
  partner_review_quiz_id: number;
  name: string;
  state: string;
  expires_at: string | null;
  response_at: string | null;
  reminder_at: string | null;
  created_at: string | null;
  updated_at: string | null;
}
