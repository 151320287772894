import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass({
    button: true,
    [`button-${_ctx.variant}`]: true,
    [`button-border-${_ctx.border}`]: true,
    [`button-size-${_ctx.size}`]: true,
    [`button-disabled`]: _ctx.disabled,

  }),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('handleClick', $event)))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}