

import {defineComponent} from "vue";
import RequestPartnerNav from '@/components/RequestPartnerNav.vue';
import WhiteBoxMain from '@/components/WhiteBoxMain.vue';

export default defineComponent( {
  name: 'RequestRejectPage',
  components: { WhiteBoxMain, RequestPartnerNav},
  setup() {
    return {
    }
  },

});
