

import {defineComponent, onMounted, ref} from "vue";
import {
  ConfigKeys,
  CreateUpdateAppConfig,
  createUpdateAppConfig,
  getAppConfig
} from '@/api/appConfigApi';
import {key} from '@/store';
import BaseButton from '@/components/BaseButton.vue';
import {notify} from '@kyvg/vue3-notification';

export default defineComponent( {
  name: 'AppConfigPage',
  components: {BaseButton},
  setup: () => {
    const formValues = ref({
      [ConfigKeys.mail_host]: '',
      [ConfigKeys.mail_from_address]: '',
      [ConfigKeys.mail_password]: '',
      [ConfigKeys.mail_port]: '',
      [ConfigKeys.mail_username]: '',
    })


    onMounted(() => {
      getAppConfig()
        .then(res => {
          res.map(config => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            formValues.value[config.key as any] = config.value;
          })
        });
    })

    const updateMailHost = () => {
      const payload: CreateUpdateAppConfig = [];

      Object.keys(formValues.value).map((key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        payload.push({key, value: formValues.value[key]})
      })


      createUpdateAppConfig(payload)
        .then(() => {
          notify({
            title: 'Has been saved successfully',
            type: 'success',
            duration: 8000,
            closeOnClick: true,
          });
        }).catch(error => {

          let errorStr = error.response.data.message as string;
          errorStr  = errorStr.replace('0.value', 'mail host').replace('3.value', 'mail port').replace('1.value', 'mail from addres').replace('2.value', 'mail password').replace('4.value', 'mail username')
        notify({
          title: errorStr,
          type: 'error',
          duration: 8000,
          closeOnClick: true,
        });
      })

    }

    return {
      updateMailHost,
      formValues,
    }
  }
});
