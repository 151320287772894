import req from '@/api/utilities/axios';

export enum ConfigKeys {
  mail_host = 'mail_host',
  mail_port = 'mail_port',
  mail_username = 'mail_username',
  mail_password = 'mail_password',
  mail_from_address = 'mail_from_address',
}

type AppConfigResource = {
  key: ConfigKeys;
  value: string | null;
}


export const getAppConfig = (): Promise<AppConfigResource[]> => {
  return req.get(`configs`).then(data => data.data.data);
};

export type CreateUpdateAppConfig = AppConfigResource[]
export const createUpdateAppConfig = (data: CreateUpdateAppConfig): Promise<AppConfigResource[]> => {
  return req.post(`configs`, data).then(data => data.data.data);
};
