

import {defineComponent, onMounted, ref} from "vue";
import RequestPartnerNav from '@/components/RequestPartnerNav.vue';
import WhiteBoxMain from '@/components/WhiteBoxMain.vue';
import {getSentRequest, PartnerReviewRequestResource} from '@/api/partnerReviewApi';
import PartnerRequestListItem from '@/components/PartnerRequestListItem.vue';

export default defineComponent( {
  name: 'RequestSentPage',
  components: {PartnerRequestListItem, WhiteBoxMain, RequestPartnerNav},
  setup() {
    const sentRequest = ref<PartnerReviewRequestResource[]>([])


    onMounted(() => {
      getSentRequest().then(res => {
        sentRequest.value = res;
      })
    })

    return {
      sentRequest
    }
  },

});
