import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7084ec82"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "question-wrapper" }
const _hoisted_2 = { class: "question-title-wrapper mb-14" }
const _hoisted_3 = { class: "question-title-index" }
const _hoisted_4 = {
  key: 0,
  class: "required-required"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 3 }
const _hoisted_8 = { class: "d-flex" }
const _hoisted_9 = { key: 4 }
const _hoisted_10 = {
  key: 0,
  class: "d-flex flex-wrap"
}
const _hoisted_11 = { key: 5 }
const _hoisted_12 = { class: "mt-20" }
const _hoisted_13 = { class: "fake-input-label d-flex justify-content-between" }
const _hoisted_14 = { class: "fake-input" }
const _hoisted_15 = {
  key: 6,
  class: "question-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_gradient = _resolveComponent("the-gradient")!
  const _component_question_title = _resolveComponent("question-title")!
  const _component_question_option_input = _resolveComponent("question-option-input")!
  const _component_question_date_input = _resolveComponent("question-date-input")!
  const _component_radio_button = _resolveComponent("radio-button")!
  const _component_SearchMultipleSelect = _resolveComponent("SearchMultipleSelect")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_search_drop_down = _resolveComponent("search-drop-down")!
  const _component_CalendarSvg = _resolveComponent("CalendarSvg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_the_gradient, {
      width: 5,
      "gradient-type": (_ctx.count  + 1) % 3 + 1 
    }, null, 8, ["gradient-type"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.count) + ".", 1),
      _createVNode(_component_question_title, {
        value: _ctx.question.text,
        "is-active": false
      }, null, 8, ["value"])
    ]),
    (_ctx.question.is_required)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, "*"))
      : _createCommentVNode("", true),
    (_ctx.question.type === 'text')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_question_option_input, {
            placeholder: "Type your response",
            value: _ctx.question.user_answer,
            onOnValueChange: _ctx.handleValueUpdate
          }, null, 8, ["value", "onOnValueChange"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.question.type === 'date')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_question_date_input, {
            placeholder: "Date",
            value: typeof _ctx.question.user_answer === 'string' ? _ctx.question.user_answer : '',
            onOnValueChange: _ctx.handleValueUpdate
          }, null, 8, ["value", "onOnValueChange"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.question.type === 'yes_no')
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.answer_values, (k, v) => {
              return (_openBlock(), _createBlock(_component_radio_button, {
                key: k,
                class: "mr-10",
                label: k,
                value: v,
                "is-active": _ctx.isResponseSelected(v),
                onHandleValueChange: _ctx.handleYesNoChange
              }, null, 8, ["label", "value", "is-active", "onHandleValueChange"]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.question.type === 'multiple')
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          (Object.keys(_ctx.question.answer_values).length < 4)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.answer_values, (k, v) => {
                  return (_openBlock(), _createBlock(_component_radio_button, {
                    key: k,
                    class: "mr-10 mb-6",
                    label: k,
                    value: v,
                    "is-active": _ctx.isResponseSelected(v),
                    onHandleValueChange: _ctx.handleMultipleChange
                  }, null, 8, ["label", "value", "is-active", "onHandleValueChange"]))
                }), 128))
              ]))
            : (_openBlock(), _createBlock(_component_SearchMultipleSelect, {
                key: 1,
                question: _ctx.question,
                onOnUpdate: _ctx.handleValueUpdate
              }, null, 8, ["question", "onOnUpdate"]))
        ]))
      : _createCommentVNode("", true),
    (_ctx.question.type === 'drop_down')
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          (Object.keys(_ctx.question.answer_values).length < 20)
            ? (_openBlock(), _createBlock(_component_select_input, {
                key: 0,
                value: _ctx.question.user_answer && _ctx.question.user_answer !== null ? _ctx.question.user_answer[0] : null,
                options: _ctx.getSelectInputOptions(_ctx.question.answer_values),
                onHandleChange: _ctx.handleSelectChange
              }, null, 8, ["value", "options", "onHandleChange"]))
            : (_openBlock(), _createBlock(_component_search_drop_down, {
                key: 1,
                options: _ctx.getSearchSelectInputOptions(_ctx.question.answer_values),
                onSelected: _ctx.handleSearchSelectChange,
                defaultValue: _ctx.question.user_answer  && _ctx.question.user_answer !== null ? {id: _ctx.question.user_answer[0], name: _ctx.question.answer_values[_ctx.question.user_answer[0]]} : null
              }, null, 8, ["options", "onSelected", "defaultValue"]))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_12, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.answer_history, (history, ix) => {
        return (_openBlock(), _createElementBlock("div", {
          key: history.id
        }, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", null, "Edits " + _toDisplayString(ix + 1), 1),
            _createElementVNode("div", null, [
              _createVNode(_component_CalendarSvg),
              _createTextVNode(" " + _toDisplayString(_ctx.formatDate(history.created_at)), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.renderAnswerValue(history.answer_value)), 1)
        ]))
      }), 128))
    ]),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
  ]))
}