
import {defineComponent, PropType} from 'vue';

export default defineComponent({
  name: 'BaseButton',
  emits: ['handleClick'],
  props: {
    variant: {
      type: String as PropType<'base' | 'red' | 'outlined'>,
      default: 'base',
    },
    border: {
      type: String as PropType<'all' | 'small'>,
      default: 'small',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<'medium' | 'small'>,
      default: 'medium',
    },
  },
});
