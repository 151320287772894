

import {defineComponent, ref} from "vue";
import WhiteBoxMain from '@/components/WhiteBoxMain.vue';
import {useRoute} from 'vue-router';
import {
  getClientPartnerReviewQuiz, PartnerReviewQuestionResource, PartnerReviewQuizResource,
  partnerReviewStoreAnswerApi
} from '@/api/partnerReviewApi';
import TheModal from '@/components/TheModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import useModal from '@/hooks/useModal';
import ReviewSentRequestQuestionAnswer from '@/components/ReviewSentRequestQuestion.vue';
import {notify} from '@kyvg/vue3-notification';

export default defineComponent( {
  name: 'ReviewSentRequestPage',
  components: {
    ReviewSentRequestQuestionAnswer,
   BaseButton, TheModal, WhiteBoxMain},
  setup() {
    const route = useRoute();
    const {
      params: { uuid },
    } = route;

    const questions = ref<Array<PartnerReviewQuestionResource & {user_answer?: string | string[] }>>([]);
    const entireServerResponse = ref<PartnerReviewQuizResource | null>(null);

    const modalError = ref('');
    //khPGQrBfeSG2mEGG
    const password = ref<string>('');
    const editingQuestionId = ref<number | null>(null);

    const enterPasswordModal = useModal(true);



    const isQuestionActive = (questionId: number) =>
      editingQuestionId.value !== null && questionId === editingQuestionId.value;


    const handleModalEnter = () => {
      getData();
    }
    const getData = async () => {
      getClientPartnerReviewQuiz(uuid as string, password.value)
      .then(response => {
        entireServerResponse.value = response;
        questions.value = response.partner_review_questions;
        enterPasswordModal.close();
      }).catch(error => {
        modalError.value = error.response.data.message
      })
    }


    const handleValueUpdate = (questionId: number, val: string) => {
      const questionIX = getQuestionIndexById(questionId);
      questions.value[questionIX].user_answer = val;
      // saveResponseWithDebounce();
    };

    const getQuestionIndexById = (questionId: number): number => {
      let questionIndex = -1;
      questions.value.forEach((question, ix) => {
        if (question.id === questionId) {
          questionIndex = ix;
        }
      });
      return questionIndex;
    };

    const saveResponse = () => {
      const payload: Record<string | number, string | string[]> = {};
      questions.value.forEach((question) => {
        if (question.user_answer && question.user_answer !== null) {

          payload[question.id] = question.user_answer;

        }
      });
      console.log({
        payload
      });
    if(!entireServerResponse.value) throw new Error('asd')
      partnerReviewStoreAnswerApi({
        request_uuid: uuid as string,
        partner_review_quiz_id: entireServerResponse.value.partner_review_request_id,
        answer: payload,
        password: password.value
      })
      .then(response => {
        debugger
        notify({
          title: 'Has been saved successfully',
          type: 'success',
          duration: 8000,
          closeOnClick: true,
        });
      }).catch(error => {
        notify({
          title: error.message,
          type: 'error',
          duration: 8000,
          closeOnClick: true,
        });
      })

    };

    return {
      password,
      handleModalEnter,
      enterPasswordModal,
      questions,

      isQuestionActive,
      handleValueUpdate,
      saveResponse,
      modalError,
    }
  },

});
