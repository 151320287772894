

import {defineComponent, onMounted, ref} from "vue";
import RequestPartnerNav from '@/components/RequestPartnerNav.vue';
import WhiteBoxMain from '@/components/WhiteBoxMain.vue';
import {
  getReviewRequests,
  PartnerReviewRequestResource
} from '@/api/partnerReviewApi';
import PartnerRequestListItem from '@/components/PartnerRequestListItem.vue';

export default defineComponent( {
  name: 'ReviewRequestPage',
  components: {PartnerRequestListItem, WhiteBoxMain, RequestPartnerNav},
  setup() {
    const reviewRequest = ref<PartnerReviewRequestResource[]>([])

    onMounted(() => {
      getReviewRequests().then(res => {
        reviewRequest.value = res;
      })
    })

    return {
      reviewRequest
    }
  },

});
