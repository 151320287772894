

import {defineComponent, onMounted, ref} from "vue";
import RequestPartnerNav from '@/components/RequestPartnerNav.vue';
import WhiteBoxMain from '@/components/WhiteBoxMain.vue';
import BaseButton from '@/components/BaseButton.vue';
import SelectInput from '@/components/SelectInput.vue';
import {getPartnerListApi} from '@/api/QuizApi';
import Multiselect from 'vue-multiselect';
import {sendRequestToPartners} from '@/api/partnerReviewApi';
import useModal from '@/hooks/useModal';
import TheModal from '@/components/TheModal.vue';
import LoadingSvg from '@/assets/img/LoadingSvg.vue';

export default defineComponent({
  name: 'SendToPartnerPage',
  components: {
    LoadingSvg,
    TheModal, SelectInput, BaseButton, WhiteBoxMain, RequestPartnerNav, Multiselect},
  setup() {
    const message = ref('');
    const reminder = ref(0);
    const expires = ref(24);

    const confirmModal = useModal(false);
    const successModal = useModal(false);

    const selectedPartners = ref<Array<{ name: string, id: number }>>([]);
    const partnersOptions = ref<Array<{ name: string, id: number }>>([]);

    onMounted(() => {
      getPartnerListApi().then(data => {
        partnersOptions.value = data.map(quiz => ({id: quiz.id, name: quiz.name}));
      })
    })
    const handleInputChange = (inputValue: string) => {
      message.value = inputValue;
    };

    const onSendNow = () => {
      confirmModal.close()
      const partnersIds = selectedPartners.value.map(el => el.id);

      sendRequestToPartners({
        expire_in: expires.value,
        message: message.value,
        quiz_ids: partnersIds
      }).then(() => {
        successModal.open()
      });

    }
    return {
      selectedPartners,
      partnersOptions,
      message,
      reminder,
      expires,
      handleInputChange,
      onSendNow,
      confirmModal,
      successModal,
    }
  },

});
