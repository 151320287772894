
import QuestionTitle from '@/components/Question/QuestionTitle.vue';
import QuestionOptionInput from '@/components/Question/options/QuestionOptionInput.vue';
import QuestionDateInput from '@/components/Question/options/QuestionDateInput.vue';
import TheGradient from '@/components/TheGradient.vue';
import RadioButton from '@/components/RadioButton.vue';
import SelectInput from '@/components/SelectInput.vue';
import { IQuestion } from '@/types/question';
import {
  computed, defineComponent,
  PropType, ref
} from 'vue';
import {useStore} from "vuex";
import {key} from "@/store";
import OptionDropDown from "@/components/OptionDropDown/OptionDropDown.vue";
import OptionDropDownRow from "@/components/OptionDropDown/OptionDropDownRow.vue";
import {updateQuestionCategoryApi} from "@/api/QuestionApi";
import SearchDropDown from '@/components/SearchDropDown.vue';
import SearchMultipleSelect from '@/components/SearchMultipleSelect.vue';
import {formatInTimeZone} from 'date-fns-tz';
import CalendarSvg from '@/assets/img/calendarSvg.vue';
import {PartnerReviewQuestionResource} from '@/api/partnerReviewApi';

export default defineComponent({
  name: 'AdminReviewSentRequestQuestionAnswer',
  components: {
    CalendarSvg,
    SearchMultipleSelect,
    QuestionTitle,
    TheGradient,
    QuestionOptionInput,
    RadioButton,
    SelectInput,
    SearchDropDown,
    QuestionDateInput,
  },
  props: {
    question: {
      type: Object as PropType<PartnerReviewQuestionResource & {user_answer?: string | string[]}>,
      required: true,
    },
    ix: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    error :{
      type: String,
      required: true,
    },
  },
  emits: ['handleValueUpdate'],
  setup(props, ctx) {
    const store = useStore(key)
    const currentCategory = computed(() => store.state.currentCategory);
    const allCategories = computed(() => store.state.categories);
    const handleValueUpdate = (val: string | Array<string>) =>
      ctx.emit('handleValueUpdate', props.question.id, val);

    const handleYesNoChange = (answerValueId: string) => {
      handleValueUpdate([answerValueId]);
    };

    const arrayIncludeOrOmit = (array: Array<string>, value: string): Array<string> => {
      const ix = array.indexOf(value);
      if (ix !== -1) {
        array.splice(ix, 1);
      } else {
        array.push(value);
      }
      return array;
    };

    const handleMultipleChange = (answerValueId: string) => {
      let res: Array<string> = [];
      if (props.question.user_answer === null || typeof props.question.user_answer === 'string') {
        res = arrayIncludeOrOmit(res, answerValueId);
      } else if (props.question.user_answer !== undefined) {
        res = arrayIncludeOrOmit(props.question.user_answer, answerValueId);
      }
      handleValueUpdate(res);
    };

    const handleSelectChange = (answerValueId: string) => {
      console.log(answerValueId)
      handleValueUpdate([answerValueId]);
    };

    const handleSearchSelectChange = (v: {id: string, name: string}) => {
      console.log({
        x: v,
      })
      handleValueUpdate([v.id]);
    }
    const isResponseSelected = (answerValueId: string) => {
      if (typeof props.question.user_answer === 'string' || props.question.user_answer === null) {
        return false;
      } else if( props.question.user_answer !== undefined) {
        return props.question.user_answer.includes(answerValueId);
      }
    };

    type IgetSelectInputOptionsReturn = Array<{ label: string, value: string}>;
    const getSelectInputOptions = (
      answerValues: Record<string, string>,
    ): IgetSelectInputOptionsReturn => {
      const response: IgetSelectInputOptionsReturn = [];
      Object.keys(answerValues).forEach((key) => {
        response.push({
          label: answerValues[key], value: key,
        });
      });
      return response;
    };
    const getSearchSelectInputOptions = (
      answerValues: Record<string, string>,
    ): Array<{ name: string, id: string}> => {
      const response: Array<{ name: string, id: string}> = [];
      Object.keys(answerValues).forEach((key) => {
        response.push({
          name: answerValues[key], id: key,
        });
      });
      return response;
    };

    const renderAnswerValue =  (value: string | string[]) => {
      if(Array.isArray(value)) {
        return value.join(', ')
      }
      return value
    }

    const formatDate = (date: string) => {
      // eslint-disable-next-line max-len
      return formatInTimeZone(new Date(date), Intl.DateTimeFormat().resolvedOptions().timeZone, 'MMM d, yyyy HH:MM');
    };

    return {
      handleValueUpdate,
      handleYesNoChange,
      isResponseSelected,
      handleMultipleChange,
      getSelectInputOptions,
      handleSelectChange,
      currentCategory,
      allCategories,
      getSearchSelectInputOptions,
      handleSearchSelectChange,

      renderAnswerValue,
      formatDate,
    };
  },
});
